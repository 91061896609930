<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    
      <b-alert
          show
          variant="info"
          class="mt-0 mb-3"
      >
          Утасны дугаар хамгийн сүүлд солигдсон огноо: <strong>{{ result }}</strong>
      </b-alert>

      
  
    </div>
</template>

<script>
import axios from 'axios';

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Customer.PayRequest",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function() {
    return {
      isLoading: false,
      selected_type: false,
      result: "",
      resultType: "text-primary",
    };
  },

  created() {
      this.getPhoneLog()
  },
  methods: {
    getPhoneLog: function(is_new) {
      this.isLoading = true;
      this.$data.resultType = "text-primary";

      // Create a FormData instance and append the customer ID
      const formData = new FormData();
      formData.append('id', this.customerId);
      axios
        .post(
          this.$config.NUMUR_MS_ADMIN_URL + "customer/get_user_phone_log",
          // "http://localhost:5000/admin/customer/get_user_phone_log",
          formData,  // Send the FormData
          {
            headers: {
              ...this.$store.getters.httpHeader,  // Include any necessary headers
              'Content-Type': 'multipart/form-data',  // Set Content-Type to multipart/form-data for FormData
            },
          }
        )
        .then((response) => {
          console.log(response);  // Optionally, for debugging
          
          if (response.data.status === "success" && response.data.data) {
            this.$data.resultType = "text-primary";
            this.$data.result = response.data.data; // Assuming the phone log data is in 'data'
          } else {
            this.$data.resultType = "text-danger";
            this.$data.result = "Дугаар одоогоор солигдоогүй байна";
          }
          
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$data.resultType = "text-danger";
          this.$data.result = "Алдаа гарсан байна";
          console.error(error);  // Optionally log the error
        });
    },
  },
};
</script>
