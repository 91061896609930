<template>
  <div class="animated fadeIn">
    <b-alert show variant="dark bg-dark" v-if="customer.blackListCount > 0">

      <strong>BLACKLIST : </strong>{{ customer.blackListMessage }}<small class="text-warning"> ({{
      customer.blackListDate }})</small>


    </b-alert>

    <b-card no-body no-focus v-if="isShow">
      <b-tabs card>
        <b-tab title="Хувийн мэдээлэл" active>
          <b-card-text>
            <b-row>
              <b-col>
                <ManualContract :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                  :isManualContract="isManualContract" v-if="isManualContract > 0"></ManualContract>
                <Judge :customerId="this.$route.params.id" :customer="customer"></Judge>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <International :customerId="this.$route.params.id" :customer="customer" :callback="loadData" v-if="['davaakhuu@numur.mn', 'urgelj@seznam.cz', 'bulgan@numur.mn'].includes($store.getters.email) ||
      checkPermission('admin.customer.international')
      "></International>
                <PhoneChangeAlert :customerId="this.$route.params.id" :customer="customer"></PhoneChangeAlert>
                <div>
                  <loading
                    :active.sync="isLoading"
                    :is-full-page="false"
                    :color="this.$config.LOADER.color"
                    :opacity="this.$config.LOADER.opacity"
                    :background-color="this.$config.LOADER.backgroundColor"
                    :z-index="10000"
                  ></loading>

                  <b-alert show variant="info" class="mt-0 mb-3">
                    Дан хамгийн сүүлд татсан огноо: <strong>{{ displayedUpdateDate }}</strong>
                  </b-alert>
                </div>
                <LoyaltyCard :customerId="this.$route.params.id"></LoyaltyCard>

                <b-card>
                  <h4 class="card-title text-uppercase text-primary">
                    {{ customer.lastname }} {{ customer.firstname }}
                  </h4>
                  <!-- <h4 class="mb-0"><small class="text-primary">{{ customer.registerNumber }} {{ customer.phoneNumber }}</small></h4> -->
                  <b-list-group class="mt-4">
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Утаc</span>
                          <h5 class="mb-0">
                            {{ customer.phoneNumber }}
                          </h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Регистр</span>
                          <h5 class="mb-0">
                            {{ customer.registerNumber }}
                          </h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">И-мэйл</span>
                          <h5 class="mb-0">{{ customer.email }}</h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Зээл эрх</span>
                          <h5 class="mb-0">
                            {{ customer.currentLimit }}₮ /
                            <small class="text-muted">{{ customer.limit }}₮</small>
                          </h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6"><span class="text-muted">Яаралтай үед холбоо барих дугаар</span>
                          <h5 class="mb-0">
                            <span v-if="customer.emergencyNumber">{{
      customer.emergencyNumber
    }}</span>
                            <span v-else>-</span>
                          </h5>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Худалдан авалтын зээлийн эрх</span>
                          <h5 class="mb-0">
                            <span v-if="customer.purchaseLimit">{{ customer.currentPurchaseLimit }}₮ /
                              <small class="text-muted">{{ customer.purchaseLimit }}₮</small></span>
                            <span v-else>-</span>
                          </h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6">
                          <IsSendSms :customer="customer" :callback="loadData"></IsSendSms>
                        </b-col>
                        <b-col sm="6"><span class="text-muted">Битүүмжлэгдсэн дүн</span>
                          <h5 class="mb-0">
                            <span v-if="customer.holdAmount">
                              {{ customer.holdAmount }} ₮
                              <b-button variant="warning" class="ml-3 btn-sm" @click="showModal">
                                Дэлгэрэнгүй
                              </b-button>
                            </span>
                            <span v-else>-</span>
                          </h5>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                    <b-list-group-item class="pl-0 rounded-0 border-right-0 border-left-0">
                      <b-row>
                        <b-col sm="6">
                          <span class="text-muted">Гэрээ байгуулсан ажилтны имэйл</span>
                          <h5 class="mb-0">
                            <span v-if="customer.contractedUserEmail">{{
      customer.contractedUserEmail
    }}</span>
                            <span v-else>-</span>
                          </h5>
                        </b-col>
                        <b-col sm="6">
                          <ContractedNumber :customer="customer" :callback="changeContractNumber" v-if="checkPermission('admin.customer.contract-number')
      "></ContractedNumber>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-card>

                <b-modal ref="purchase-loan-requests-modal" size="xl">
                  <template slot="modal-title">
                    Битүүмжлэгдсэн зээлийн жагсаалт
                  </template>
                  <div class="d-block">
                    <b-table stacked="md" hover bordered small :items="customer.purchaseLoanRequests"
                      :fields="purchaseLoanRequestColumns">
                      <template v-slot:cell(index)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template v-slot:cell(createdDate)="data">
                        {{ data.item.createdDate }}
                      </template>
                      <template v-slot:cell(merchant)="data">
                        {{ data.item.merchant.name }}
                      </template>
                      <template v-slot:cell(amount)="data">
                        {{ (data.item.amount * 1) | currecry }}₮
                      </template>
                      <template v-slot:cell(interest)="data">
                        {{ data.item.interest }}%
                      </template>
                      <template v-slot:cell(status)="data">
                        <b-badge variant="primary" v-if="data.item.status === 'NEW'">Шинэ</b-badge>
                        <b-badge variant="success" v-else-if="data.item.status === 'SUCCESS'">Зөвшөөрсөн</b-badge>
                        <b-badge variant="danger" v-else>Татгалзсан</b-badge>
                      </template>
                      <template v-slot:cell(expiryDate)="data">
                        {{ data.item.expiryDate }}
                      </template>
                    </b-table>
                  </div>
                  <template slot="modal-footer"></template>
                </b-modal>
              </b-col>
              <b-col md="6">
                <Distribute :customerId="this.$route.params.id" v-if="checkPermission('admin.customer.distribute')">
                </Distribute>
                <Tag :customerId="id" v-if="!this.checkNpl()"></Tag>
                <RepaymentSchedule :customerId="id"></RepaymentSchedule>
              </b-col>
              <b-col>
                <b-card title="Тэмдэглэл">
                  <Note :customerId="id" :reload="noteReload"></Note>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Үйлдэл">
          <b-card-text>
            <b-row>
              <b-col sm="6">
                <UserOneLoginCode :customerId="this.$route.params.id" :customer="customer" v-if="checkPermission('admin.customer.get-user-one-login-code') &&
      customer.isContract == 0
      "></UserOneLoginCode>
                <PayRequest :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                  v-if="checkPermission('admin.customer.create-pay-request')"></PayRequest>
                <b-row>
                  <b-col md="6">
                    <ConfirmCode :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                      v-if="checkPermission('admin.customer.create-confirm-code')"></ConfirmCode>

                  </b-col>
                  <b-col md="6">
                    <DeviceConfirmCode :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                      v-if="checkPermission('admin.customer.device-confirm-code')"></DeviceConfirmCode>
                  </b-col>
                </b-row>
                <b-card>
                  <!-- <PutInBlackList
            class="mr-3 mb-3"
            :customerId="this.$route.params.id"
            :customer="customer"
            :callback="loadData"
            v-if="checkPermission('admin.customer.black-list')"
            ></PutInBlackList> -->
                  <EditProfile class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission('admin.customer.change-info')"></EditProfile>
                  <InfoLogReport class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    v-if="!this.checkNpl()"></InfoLogReport>
                  <!-- <ChangeRegister class="mr-3" :customerId="this.$route.params.id" :customer="customer" :callback="loadData"  v-if="checkPermission('admin.customer.change-register')"></ChangeRegister> -->
            <ChangePhone class="mr-3" :customerId="this.$route.params.id" :customer="customer" :callback="loadData" v-if="checkPermission('admin.customer.change-phone')"></ChangePhone>

                  <ChangeEmail class="mr-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission('admin.customer.change-email')"></ChangeEmail>

                  <hr style="clear: both;">
                  <ChangeActive class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission('admin.customer.change-active')"></ChangeActive>
                  <ChangeLoanActive class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission('admin.customer.change-loan-active') &&
      isManualContract == 0
      "></ChangeLoanActive>
                  <ChangeLoanActiveAdmin class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission(
      'admin.customer.change-loan-active-master'
    ) &&
      isManualContract == 0 &&
      customer.isIncomeInfo == 0
      "></ChangeLoanActiveAdmin>
                  <ChangeDanConnect class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission(
      'admin.customer.change-dan'
    )
      "></ChangeDanConnect>
        <CheckStarNew class="mr-3 mb-3" :customerId="this.$route.params.id" :customer="customer"
                    :callback="loadData" v-if="checkPermission('admin.customer.check-star')"></CheckStarNew>
      <BlackList class="mr-3" :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                    v-if="checkPermission('admin.customer.add-blacklist') && customer.blackListCount == 0"></BlackList>
                  <hr style="clear: both;">
                  <b-button
  variant="info"
  class="pull-left mr-3 mb-3"
  size="sm"
  @click="getNoLoanHTML(customer)"
  style="width: 100px;"
  v-if="checkPermission('admin.loan-repayment-overdue.create-statement-no-loan')"
>
  Өр зээлгүй
</b-button>

<b-button
  variant="info"
  class="pull-left mr-3 mb-3"
  size="sm"
  style="width: 100px;"
  v-else
>
  <span>Уншиж байна</span>
</b-button>
                  <b-button variant="info" class="pull-left mr-3 mb-3" size="sm" :to="{
      name: 'admin.customer-loan-accounts.extend-generate',
      query: { p: customer.phoneNumber },
    }" target="_blank" style="width: 200px;" v-if="checkPermission('admin.customer.calculation')">Зээл
                    сунгалтын тооцоолуур</b-button>
                  <!-- <b-button
            variant="info"
            class="pull-left mr-3 mb-3"
            :disabled="emailButtonLoading"
            size="sm"
            @click="sendNoLoanHTML(customer)"
            style="width: 150px;"
            v-if="
                checkPermission(
                'admin.loan-repayment-overdue.create-statement-no-loan'
                )
            "
            ><span v-if="!emailButtonLoading">Өр зээлгүй имэйл</span>
            <span v-else>Уншиж байна</span></b-button
            > -->

                  <b-button variant="warning" class="pull-left mr-3 mb-3" :disabled="stopInterestButtonLoading"
                    size="sm" @click="setInterestStop(customer)" style="width: 150px;"
                    v-if="checkPermission('admin.customer.stop-interest')"><span v-if="!stopInterestButtonLoading">Хүү
                      зогсоох</span>
                    <span v-else>Уншиж байна</span></b-button>
                  <b-button variant="info" class="pull-left mr-3 mb-3" size="sm"
                    @click="getCustomerRepaymentSchedule(customer)">Эргэн төлөлтийн хуваарь</b-button>
                  <hr style="clear: both;">

                  <b-button variant="info" class="pull-left mr-3 mb-3" :disabled="cibButtonLoading" size="sm"
                    @click="updateCib(customer, null, null)" style="width: 150px;"
                    v-if="checkPermission('admin.cib.update')"><span v-if="!cibButtonLoading">ЗМС шинэчлэх</span>
                    <span v-else>Уншиж байна</span></b-button>
                  <b-button variant="info" class="pull-left mr-3 mb-3" :disabled="cibButtonLoading" size="sm"
                    @click="closedLoanLine(customer)" style="width: 150px;"
                    v-if="checkPermission('admin.cib.closedLoanLine')"><span v-if="!cibButtonLoading">Зээлийн шугам
                      хаах</span>
                    <span v-else>Уншиж байна</span></b-button>
                  <b-button variant="info" class="pull-left mr-3 mb-3" :disabled="cibButtonLoading" size="sm"
                    @click="changeLoanLineShowModal(customer)" style="width: 250px;"
                    v-if="checkPermission('admin.cib.changeLoanLine')"><span v-if="!cibButtonLoading">Зээлийн шугам
                      ангилал
                      өөрчлөх</span>
                    <span v-else>Уншиж байна</span></b-button>

                  <b-modal ref="change-class-loan-line-modal" @ok="changeLoanLine(customer)">
                    <template slot="modal-title">
                      Зээлийн шугам ангилал өөрчлөх
                    </template>
                    <div class="d-block">
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Ангилал">
                            <b-form-select v-model="selectedTypeCib" class="border " :class="{
      'border-secondary': selectedTypeCib,
      'border-danger': !selectedTypeCib,
    }">
                              <option :value="null">Ангилал сонгоно уу</option>
                              <option :value="item" :key="index" v-for="(item, index) in loanClasses">{{ item.name }}
                              </option>
                            </b-form-select>
                          </b-form-group>
                          <p class="text-danger mb-0">
                            Та дээрх зээлийн мэдээллийг
                            <strong>"Зээлийн мэдээллийн сан"</strong> - руу ангилал өөрчлөх
                            хүсэлт явуулахдаа итгэлтэй байна уу?
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                    <template slot="modal-footer"></template>
                  </b-modal>



                  <b-button variant="success" class="pull-left mr-3 mb-3" :disabled="cibButtonLoading" size="sm"
                    @click="newLoanLineShowModal()" style="width: 250px;"
                    v-if="checkPermission('admin.cib.newLoanLine')"><span v-if="!cibButtonLoading">Шинэ зээлийн шугам
                      үүсгэх</span>
                    <span v-else>Уншиж байна</span></b-button>

                  <b-modal ref="new-loan-line-modal" @ok="newLoanLine(customer)">
                    <template slot="modal-title">
                      Шинэ зээлийн шугам үүсгэх
                    </template>
                    <div class="d-block">
                      <b-alert variant="danger" v-if="!customer.isCibClosed" show>Идэвхтэй зээлийн шугам байна.
                        <b-button variant="danger" size=sm target="_blank" :to="{ name: 'admin.cib.get-pdf' }"
                          v-if="checkPermission('admin.cib.get-pdf')"> PDF татах </b-button>
                      </b-alert>
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Үүсгэх огноо">
                            <date-picker id="input-transactionDate" v-model="selectedDateCib"
                              :config="{ format: 'YYYY-MM-DD', useCurrent: false }" required :class="{
      'border-secondary': selectedDateCib,
      'border-danger': !selectedDateCib,
    }"></date-picker>
                          </b-form-group>

                          <p class="text-danger mb-0">
                            Зээлийн шугам шинээр үүсгэхдээ итгэлтэй байна уу?
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                    <template slot="modal-footer"></template>
                  </b-modal>
                </b-card>

                <Poll :customer="customer" v-if="checkPermission('admin.customer.poll') && !this.checkNpl()"></Poll>
              </b-col>
              <b-col sm="6">
                <NoLoan :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                  v-if="checkPermission('admin.customer.no-loan') && !this.checkNpl()"></NoLoan>
                <Message :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                  v-if="checkPermission('admin.customer.message')"></Message>
                <Email :customerId="this.$route.params.id" :customer="customer" :callback="loadData"
                  v-if="checkPermission('admin.customer.email')"></Email>

              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Нэмэлт мэдээлэл" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <b-card no-body>
                  <b-tabs pills card>
                    <b-tab title="Нэмэлт">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <CustomerExtraInfo :customerId="id"></CustomerExtraInfo>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Emergency">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Emergency :customerId="id"></Emergency>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Зээлийн түүх" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <LoanAccountHistory :customerId="id"></LoanAccountHistory>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Гүйлгээний түүх" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <b-card no-body>
                  <b-tabs card pills>
                    <b-tab title="Гүйлгээний түүх">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <LoanTransactionHistory :customerId="id"></LoanTransactionHistory>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Түүх" v-if="checkPermission('admin.customer-account-transaction.list')">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <CustomerAccountTransaction :customerId="id"></CustomerAccountTransaction>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Loyalty" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <b-card no-body>
                  <b-tabs pills card>
                    <b-tab title="Эрх нэмэх">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Star :customerId="id"></Star>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Loyalty">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Loyalty :customerId="id"></Loyalty>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>

              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Мэдэгдэл" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <Statement :customerId="id"></Statement>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <!-- <b-tab title="Facebook">
                <b-card-text>
                    <b-row>
                        <b-col sm=12>
                            <Facebook :customerId="id"></Facebook>   
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-tab> -->
        <b-tab title="PDF" v-if="checkPermission('admin.customer.pdf-list') && !this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <PdfList :registerNumber="customer.registerNumber"></PdfList>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Киоск" v-if="checkPermission('admin.customer.pdf-list') && !this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <Contract :registerNumber="customer.registerNumber"></Contract>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="ХУР & ДАН" v-if="checkPermission('admin.customer.xyp')">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <b-card no-body>
                  <b-tabs pills card>
                    <b-tab title="ХУР" v-if="checkPermission('admin.customer.xyp')">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Xyp :customer="customer"></Xyp>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="DAN" v-if="checkPermission('admin.customer.xyp')">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Dan :customer="customer"></Dan>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab title="Зээл хүсэлт" v-if="!this.checkNpl()">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <b-card no-body>
                  <b-tabs card pills>
                    <b-tab title="Машины зээл хүсэлт">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Trad :customerId="id"></Trad>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                    <b-tab title="Оюутаны зээл хүсэлт">
                      <b-card-text>
                        <b-row>
                          <b-col sm="12">
                            <Student :customerId="id"></Student>
                          </b-col>
                        </b-row>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <!-- <b-tab title="Чат бот мессеж лог">
          <b-card-text>
            <b-row>
              <b-col sm="12">
                <ChatBot :customer="customer"></ChatBot>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab> -->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import moment from "moment";
import RepaymentSchedule from "./Detail/RepaymentSchedule.vue";
import LoanAccountHistory from "./Detail/LoanAccountHistory.vue";
import LoanTransactionHistory from "./Detail/LoanTransactionHistory.vue";
import ChangePhone from "./Detail/ChangePhone.vue";
import EditProfile from "./Detail/EditProfile.vue";
import ChangeRegister from "./Detail/ChangeRegister.vue";
import ChangeActive from "./Detail/ChangeActive.vue";
import ChangeEmail from "./Detail/ChangeEmail.vue";
import ChangeLoanActive from "./Detail/ChangeLoanActive.vue";
import ChangeLoanActiveAdmin from "./Detail/ChangeLoanActiveAdmin.vue";
import ChangeDanConnect from "./Detail/ChangeDanConnect.vue";
import ManualContract from "./Detail/ManualContract.vue";
import Star from "./Detail/Star.vue";
import Statement from "./Detail/Statement.vue";
import Message from "./Detail/Message.vue";
import CheckStar from "./Detail/CheckStar.vue";
import CheckStarNew from "./Detail/CheckStarNew.vue";
import PutInBlackList from "./Detail/PutInBlackList.vue";
import Facebook from "./Detail/Facebook.vue";
import PdfList from "./Detail/PdfList.vue";
import Contract from "./Detail/Contract.vue";
import Poll from "./Detail/Poll.vue";
import Xyp from "./Detail/Xyp.vue";
import Dan from "./Detail/Dan.vue";
import Email from "./Detail/Email.vue";
import Emergency from "./Detail/Emergency.vue";
import PayRequest from "./Detail/PayRequest.vue";
import InfoLogReport from "./Detail/InfoLogReport.vue";
import Trad from "./Detail/Trad.vue";
import Student from "./Detail/Student.vue";
import ChatBot from "./Detail/ChatBot.vue";
import ConfirmCode from "./Detail/ConfirmCode.vue";
import DeviceConfirmCode from "./Detail/DeviceConfirmCode.vue";
import UserOneLoginCode from "./Detail/UserOneLoginCode.vue";
import Loyalty from "./Detail/Loyalty.vue";
import LoyaltyCard from "./Detail/LoyaltyCard.vue";
import Distribute from "./Detail/Distribute.vue";
import IsSendSms from "./Detail/IsSendSms.vue";
import ContractedNumber from "./Detail/ContractedNumber.vue";
import CustomerAccountTransaction from "./Detail/CustomerAccountTransaction.vue";
import CustomerExtraInfo from "./Detail/CustomerExtraInfo.vue";
import PhoneChangeAlert from "./Detail/PhoneChangeAlert.vue";
import Judge from "./Detail/Judge.vue";
import Tag from "./Detail/Tag.vue";
import International from "./Detail/International.vue";
import ActionTab from "./Detail/Action.vue";
import NoLoan from "./Detail/NoLoan.vue"
import BlackList from "./Detail/BlackList.vue"

export default {
  name: "Customers.xypInfo",
  components: {
    RepaymentSchedule,
    LoanAccountHistory,
    LoanTransactionHistory,
    ChangePhone,
    EditProfile,
    ChangeRegister,
    ChangeActive,
    UserOneLoginCode,
    ChangeEmail,
    ChangeLoanActive,
    ChangeLoanActiveAdmin,
    DeviceConfirmCode,
    ManualContract,
    Star,
    Statement,
    Message,
    Email,
    CheckStar,
    CheckStarNew,
    PutInBlackList,
    Facebook,
    PdfList,
    Contract,
    Poll,
    Xyp,
    Dan,
    Emergency,
    PayRequest,
    InfoLogReport,
    Trad,
    ChatBot,
    ConfirmCode,
    Loyalty,
    Student,
    LoyaltyCard,
    Distribute,
    IsSendSms,
    ContractedNumber,
    CustomerAccountTransaction,
    CustomerExtraInfo,
    PhoneChangeAlert,
    Judge,
    Tag,
    International,
    ActionTab,
    NoLoan,
    ChangeDanConnect,
    BlackList
  },
  data: function () {
    return {
      selectedDateCib: null,
      selectedTypeCib: null,
      loanClasses: [],
      isShow: true,
      stopInterestButtonLoading: false,
      emailButtonLoading: false,
      cibButtonLoading: false,
      noteReload: false,
      isLoading: false,
      customer: {},
      id: this.$route.params.id,
      isManualContract: 0,
      purchaseLoanRequestColumns: [
        { label: "№", key: "index", class: "text-center" },
        { label: "Огноо", key: "createdDate", class: "text-center" },
        { label: "Мерчант", key: "merchant", class: "text-center" },
        { label: "Дүн", key: "amount", class: "text-center" },
        { label: "Хүү", key: "interest", class: "text-center" },
        { label: "Төлөв", key: "status", class: "text-center" },
        { label: "Дуусах огноо", key: "expiryDate", class: "text-center" },
      ],
    };
  },
  created() {
    this.checkManualContract();
    this.checkCustomer();
    this.getLoanClass();
  },
  computed: {
  displayedUpdateDate() {
    const date1 = this.customer.danUpdatedDate ? new Date(this.customer.danUpdatedDate) : null;
    const date2 = this.customer.xypUpdatedDate ? new Date(this.customer.xypUpdatedDate) : null;

    // If both dates are null, show "Don't have"
    if (!date1 && !date2) {
      return "Дан эсвэл Хур татаагүй байна";
    }
    // If both dates are available, return the latest
    if (date1 && date2) {
      return date1 > date2 ? this.customer.danUpdatedDate : this.customer.xypUpdatedDate;
    }
    
    // If only one date is available, return it
    return date1 ? this.customer.danUpdatedDate : (date2 ? this.customer.xypUpdatedDate : null);
  }
},
  methods: {
    checkCustomer: function () {
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/check_customer", {
          params: { customer_id: this.$data.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            console.log("CHECK");
            console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.isShow = true;
              this.loadData();
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
              this.$data.isShow = false;
            }
          },
          (response) => {
            this.$data.isShow = false;
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    getLoanClass: function () {
  axios
    .get(this.$config.NUMUR_MS_ADMIN_URL + "get_reference", {
      headers: {
        ...this.$store.getters.httpHeader,
        'Content-Type': 'application/json', // Ensure Content-Type is application/json
      },
    })
    .then((response) => {
      this.$data.loanClasses = response.data.data;
    })
    .catch((error) => {
      // Handle the error here if needed
      console.error(error);
    });
},
    checkManualContract() {
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/check_manual_contract",
          { customer_id: this.$data.id },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            // //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.isManualContract = parseInt(response.body.successData);
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    loadData: function () {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_customer_info", {
          params: { id: this.$route.params.id },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.customer = response.body;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
      this.$data.noteReload = !this.$data.noteReload;
    },
    getNoLoanHTML: function (item) {
  this.isLoading = true;

  // Show a prompt dialog to ask for 'where' location
  const where = prompt("Байгуулгын нэр:");
  const number = prompt("Албан тоот:");

  // Send the request to the backend with the customer data and location (where)
  axios.get(this.$config.NUMUR_MS_ADMIN_URL + 'no_loan_html', {
    params: { 
      id: item.id,          // Customer ID
      number: item.number,   // Optional number if needed
      where: where || '',
      number: number || ''      // Pass the 'where' value (default to empty string if not provided)
    },
    headers: this.$store.getters.httpHeader,
  })
  .then((response) => {
    this.isLoading = false;

    var mywindow = window.open("", "Print", "_blank");

    if (response.data.data) {
      mywindow.document.write(response.data.data.html_content);  // Write the HTML content in the new window

      // Extract the base64 image and filename for download
      const imgBase64 = response.data.data.img_base64;
      const imgFilename = response.data.data.img_filename;

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = `data:image/jpeg;base64,${imgBase64}`;  // Image source with base64 data
      link.download = imgFilename;  // Set the filename for download

      // Append the link to the document body (required for some browsers)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Optionally, remove the link after the download starts
      document.body.removeChild(link);
    } else {
      mywindow.document.write("Амжилтгүй"); // Failure message
    }
  })
  .catch((error) => {
    this.isLoading = false;
    console.error("Error occurred:", error);
  });
},
    getCustomerRepaymentSchedule: function (item) {
      this.isLoading = true;
      axios.get(this.$config.NUMUR_MS_ADMIN_URL + 'get_repayment_schedule_html',
          {
            params: { id: item.id },
         
            headers: this.$store.getters.httpHeader,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            if (response.data.successData) {
              mywindow.document.write(response.data.successData);
              setTimeout(function () {
                mywindow.print();
              }, 300);
            } else {
              mywindow.document.write("Амжилтгүй");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sendNoLoanHTML: function (item) {
      this.emailButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/send_no_loan_html", {
          params: { id: item.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
              this.loadData();
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
            this.emailButtonLoading = false;
          },
          () => {
            this.emailButtonLoading = false;
          }
        );
    },
    updateCib: function (item, classCode, newLoanLineDate) {
      this.cibButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/update_cib", {
          params: { id: item.id, classCode: classCode, newLoanLineDate: newLoanLineDate },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            console.log(response)
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
            this.cibButtonLoading = false;
          },
          () => {
            this.cibButtonLoading = false;
          }
        );
    },
    setInterestStop: function (item) {
      this.stopInterestButtonLoading = true;
      // this.$http
      //   .get(this.$config.API_URL + "CustomerWebService/set_interest_stop", {
      //     params: { id: item.id },
      //     emulateJSON: true,
      //     headers: this.$store.getters.httpHeader,
      //   })
      //   .then(
      //     (response) => {
      //       console.log(response);
      //       if (response.body.responseResultType === "SUCCESS") {
      //         this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
      //       } else {
      //         this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
      //       }
      //       this.stopInterestButtonLoading = false;
      //     },
      //     () => {
      //       this.stopInterestButtonLoading = false;
      //     }
      //   );
      axios
          .post(this.$config.NUMUR_MS_ADMIN_URL + "loan/set_interest_stop",
              {
                "id": item.id,
                "email": this.$store.getters.email
              },
              {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json",
              },
          })
          .then(
            (response) => {
              console.log(response);
              if (response.data.message === "success") {
                this.showToast("Амжилттай", "Амжилттай хадгаллаа", "success");
              } else {
                this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
              }
              this.stopInterestButtonLoading = false;
            },
            () => {
              this.stopInterestButtonLoading = false;
            }
          );
    },
    closedLoanLine: function (customer) {
      this.cibButtonLoading = true;
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/close_loan_line", {
          params: { id: customer.id },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            if (response.body.responseResultType === "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
            } else {
              this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
            }
            this.cibButtonLoading = false;
          },
          () => {
            this.cibButtonLoading = false;
          }
        );
    },
    changeLoanLine: function (customer) {
      if (this.$data.selectedTypeCib) {
        this.updateCib(customer, this.$data.selectedTypeCib.cibCode, null)
      } else {
        // this.changeLoanLineShowModal()
        this.showToast("Анхаар", "Ангилал сонгоогүй байна", "warning");

      }
    },
    newLoanLine: function (customer) {
      if (this.$data.selectedDateCib) {
        this.updateCib(customer, null, this.$data.selectedDateCib)
      } else {
        // this.changeLoanLineShowModal()
        this.showToast("Анхаар", "Та огноо сонгоогүй байна", "warning");

      }
    },
    newLoanLineShowModal: function () {
      this.$refs["new-loan-line-modal"].show();
    },
    changeLoanLineShowModal: function () {
      this.$refs["change-class-loan-line-modal"].show();
    },
    changeContractNumber: function (_v) {
      this.$data.customer.contractedNumber = _v;
    },
    showModal() {
      // this.$data.form = {
      //   id: this.customerId,
      //   lastname: this.customer.lastname,
      //   firstname: this.customer.firstname,
      //   phone1: this.customer.phoneNumber,
      //   phone2: this.customer.emergencyNumber,
      //   type: "many_change",
      //   email1: this.customer.email,
      //   registerNumber: this.customer.registerNumber,
      // };
      this.$refs["purchase-loan-requests-modal"].show();
    },
    hideModal() {
      this.$refs["purchase-loan-requests-modal"].hide();
    },
    loadNote() {
      this.noteReload = !this.noteReload;
    },
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted
      this.selected = ctx.selectedYMD
    }
  },
};
</script>