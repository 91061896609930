<template>
  <b-row>
    <b-col>
      <div>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="'Зээлийн түүх.xls'"
            v-if="items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>

          <b-button
            class="ml-3 pull-right"
            variant="primary"
            v-if="
              parseFloat(payment.over.replace(/,/g, '')) > 0 && isActiveLoan
            "
            :to="{ name: 'admin.customer.closing', params: { id: customerId } }"
            >Төлбөр бүртгэх
          </b-button>

          <b-button
            class="ml-3 pull-right"
            variant="warning"
            v-if="
              parseFloat(payment.over.replace(/,/g, '')) > 0 &&
                isActiveLoan &&
                isOverduePayment
            "
            @click="overdueLoanRepayment(true)"
            >Хугацаа хэтэрсэн төлбөр бүртгэх
          </b-button>

          <b-button
            class="ml-3 pull-right"
            variant="warning"
            v-if="isCredit && isActiveLoan &&
                      checkPermission(
                        'admin.loan-account-history.iluu-tulult-hasah'
                      )
                    "
            @click="overdueLoanRepayment(false)"
            >Илүү төлөлт хасах
          </b-button>

          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row>
            <b-form @submit.stop.prevent="submitSearch" class="col-md-4">
              <b-input-group>
                <b-input-group-prepend is-text
                  ><i class="fa fa-search"></i
                ></b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  placeholder="Хайх"
                ></b-form-input>
                <b-input-group-append
                  ><b-button variant="primary" type="submit"
                    >Хайх</b-button
                  ></b-input-group-append
                >
                <b-input-group-append
                  ><b-button variant="secondary" @click="loadData"
                    ><i class="fa fa-refresh"></i
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </b-row>
        </div>
        <div
          class="table-responsive"
          v-if="items != undefined && items.length > 0"
        >
          <b-table
            id="filter-table"
            stacked="md"
            @sort-changed="sortingChanged"
            hover
            bordered
            small
            responsive="sm"
            :items="items"
            :fields="columns"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              <span v-if="data.index <= pagination.to - pagination.from">{{
                data.index + pagination.from
              }}</span>
            </template>
            <template v-slot:cell(accountNumber)="data">
              {{ data.item.accountNumber }} <br />
              <small variant="info" class="text-muted">{{
                data.item.merchantName
              }}</small>
              <!-- v-else-if="data.item.isClosed != null" -->
              <b-badge variant="danger" v-if="data.item.isJudge"
              > Шүүхэд шилжсэн</b-badge>
            </template>
            <template v-slot:cell(parent)="data">
              {{
                data.item.hasOwnProperty("parent") ? data.item.parent.name : ""
              }}
            </template>
            <template v-slot:cell(nicename)="data">
              {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
            </template>
            <template v-slot:cell(days)="data">
              <strong v-if="data.index <= pagination.to - pagination.from">
                {{ data.item.days }} өдөр</strong
              >
            </template>
            <template v-slot:cell(isClosed)="data">
              <b-badge variant="success" v-if="data.item.isClosed"
                >Тийм</b-badge
              >
              <b-badge variant="danger" v-else-if="data.item.isClosed != null"
                >Үгүй</b-badge
              >
            </template>
            <template v-slot:cell(term)="data">
              <span v-if="data.index <= pagination.to - pagination.from">
                {{ data.item.term }}
                <span
                  v-if="
                    data.item.hasOwnProperty('noInterestMonth') &&
                      data.item.noInterestMonth > 0
                  "
                  class="text-danger"
                  >({{ data.item.noInterestMonth }})</span
                >
              </span>
              <strong v-else class="pull-right">{{ data.item.term }}</strong>
            </template>
            <template v-slot:cell(closingAmount)="data">
              <b-link
                v-if="!data.item.isClosed"
                v-b-modal="'modal-' + data.index"
                >{{ data.item.closingAmount }}</b-link
              >
              <b-modal :id="'modal-' + data.index" title="Задаргаа" hide-footer>
                <template slot="default" class="mt-5">
                  <b-list-group class="text-left" v-if="data.item.loanBalance">
                    <b-list-group-item
                      >Үндсэн зээл
                      <span class="pull-right text-primary"
                        >{{ data.item.loanBalance }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      >Хуримтлагдсан хүү
                      <span class="pull-right text-primary"
                        >{{ data.item.interestAmount }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      >Олголтын шимтгэл
                      <span class="pull-right text-primary"
                        >{{ data.item.takeFeeAmount }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      >Алданги
                      <span class="pull-right text-primary"
                        >{{ data.item.unduelossAmount }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      >Өдрийн хүү
                      <span class="pull-right text-primary"
                        >{{
                          parseFloat(
                            (parseFloat(
                              data.item.loanBalance.replace(/,/g, "")
                            ) *
                              data.item.dayInterest) /
                              100
                          ) | currecry
                        }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item
                      >Нэмэгдүүлсэн хүү
                      <span class="pull-right text-primary"
                        >{{
                          parseFloat(
                            data.item.undueLossInterest
                              ? (parseFloat(
                                  data.item.loanBalance.replace(/,/g, "")
                                ) *
                                  data.item.undueLossInterest) /
                                  100
                              : "0"
                          ) | currecry
                        }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item>
                      Хойшлуулсан хүүний дүн
                      <span class="pull-right text-primary"
                        >{{ data.item.stopInterestAmount }}₮</span
                      ></b-list-group-item
                    >
                    <b-list-group-item>
                      Гэрээний дугаар
                      <span class="pull-right text-primary">{{
                        data.item.contractNumber
                      }}</span></b-list-group-item
                    >
                  </b-list-group>
                </template>
              </b-modal>
            </template>
            <template v-slot:cell(actions)="data">
              <b-button-group
                v-if="data.index <= pagination.to - pagination.from"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  :to="{
                    name: 'admin.customer-loan-accounts.detail',
                    params: { id: data.item.id },
                  }"
                >
                  <i class="fa fa-id-card"></i>
                </b-button>
                <Interest
                  :account="data.item"
                  v-if="checkPermission('admin.customer.xyp')"
                ></Interest>
              </b-button-group>
            </template>

            <template v-slot:cell(typeCode)="data">
              <b-link
                size="sm"
                variant="primary"
                style="float:right"
                v-if="
                  checkPermission('admin.loan.co-customer') &&
                    data.item.coCustomerId
                "
                :to="{
                  name: 'admin.customer.detail',
                  params: { id: data.item.coCustomerId },
                }"
                target="_blank"
              >
                хамтран
              </b-link>

                {{ $store.getters.loan_type[data.item.typeCode ] }}
                              <br />
              <small class="text-muted" v-if="data.item.logCreatedBy"
                >@{{ data.item.logCreatedBy }} -
                {{ data.item.logDescription }}</small
              >
            </template>
          </b-table>

          <div slot="footer" class="mb-0 mt-3">
            <nav class="pull-right" v-if="pagination.total > query.per_page">
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>

        

        <b-alert variant="warning" v-else show class="mb-0">
          Мэдээлэл байхгүй байна
        </b-alert>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import axios from "axios";
import Interest from "./LoanAccountInterest.vue";
export default {
  name: "Customers.loanAccountHistory",
  components: { axios, Interest },
  props: ["customerId"],
  data: function() {
    return {
      isCredit: false,
      isLoading: false,
      filterShow: false,
      isOverduePayment: false,
      query: {
        id: 0,
        page: 1,
        per_page: 10,
        orderDirection: "DESC",
        orderBy: "loan_date",
        search: "",
        columns: "",
      },
      payment: {
        over: "0",
        under: "0",
        undueLoss: "0",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      downloadAccount: {},
      columns: [
        { label: "№", key: "index", class: "text-center" },
        {
          label: "Төрөл",
          key: "typeCode",
          sortable: true,
          sort_key: "type_code",
        },
        { label: "Дансны дугаар", key: "accountNumber" },
        {
          label: "Авсан огноо",
          key: "loanDate",
          sortable: true,
          sort_key: "loan_date",
        },
        { label: "Хаасан огноо", key: "closedDate" },
        { label: "Ашигласан хоног", key: "days" },
        { label: "Хаагдсан эсэх", key: "isClosed", class: "text-center" },
        {
          label: "Хаах огноо",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date",
        },
        { label: "Хүү", key: "interest", class: "text-center" },
        { label: "Хугацаа", key: "term", class: "text-center" },
        { label: "Хаах дүн", key: "closingAmount", class: "text-right" },
        {
          label: "Авсан хэмжээ",
          key: "loanAmount",
          class: "text-right",
          sortable: true,
          sort_key: "loan_amount",
        },
        {
          label: "Үлдэгдэл",
          key: "loanBalance",
          class: "text-right",
          sortable: true,
          sort_key: "loan_balance",
        },
        // { label: '', key: 'overPaymentAmount', class: 'text-right'},
        // { label: '', key: 'underPaymentAmount', class: 'text-right'}
        { label: "Үйлдэл", key: "actions", class: "text-center" },
      ],
      isActiveLoan: false,
    };
  },
  created: function() {
    this.$data.query.columns = [
      "firstname",
      "lastname",
      "register",
      "phone",
      "email",
      "loan_max_amount",
      "created_date",
      "contracted_date",
    ].join(",");
    this.$data.query.id = this.customerId;
    this.$data.download.header = {};
    for (let i in this.$data.columns) {
      let _field = this.$data.columns[i];
      if (_field.label != "Үйлдэл")
        this.$data.download.header[_field.label] = _field.key;
    }
    this.loadData();
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadData: function() {
      this.checkSession();
      this.isLoading = true;
      axios
          // .post(this.$config.NUMUR_MS_ADMIN_URL + "loan-account-history",
          .post(this.$config.NUMUR_MS_ADMIN_URL + "loan-account-history",
              JSON.stringify(this.$data.query),
              {
                headers: {
                  ...this.$store.getters.httpHeader, // Include your custom headers
                  "Content-Type": "application/json", // Ensure JSON content type
                },
              })
      // this.$http
      //   .get(
      //     this.$config.API_URL + "CustomerWebService/get_loan_account_history",
      //     {
      //       params: this.$data.query,
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
        .then(
          (response) => {
            this.isLoading = false;
            console.log("Loan account history: ", response.data.data.data)
            // this.$data.items = response.body.data;
            // this.$data.pagination.total = response.body.total;
            // this.$data.pagination.from = response.body.from;
            // this.$data.pagination.to = response.body.to;

            this.$data.items = response.data.data.data;
            this.$data.pagination.total = response.data.data.total;
            this.$data.pagination.from = response.data.data.from;
            this.$data.pagination.to = response.data.data.to;

            this.$data.isOverduePayment = false;
            this.$data.isActiveLoan = false;
            var today = moment().format("YYYY-MM-DD");
            for (var i in this.$data.items) {
              this.$data.downloadAccount[this.$data.items[i].id] = false;
              if (!this.$data.items[i].isClosed) this.$data.isActiveLoan = true;
              if (
                today > this.$data.items[i].expiryDate &&
                !this.$data.items[i].isClosed
              ) {
                this.$data.isOverduePayment = true;
              }
              // //console.log(this.$data.items[i].typeCode);
              if (parseInt(this.$data.items[i].typeCode.substring(1,2)) > 2) {
                this.$data.isCredit = true;
              }
            }

            this.calcOverAndUnderPayment();
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    calcOverAndUnderPayment: function() {
      if (this.$data.items != null && this.$data.items.length > 0) {
        if (
          this.$data.items[0].feeAmount != "0" &&
          this.$data.items[0].feeAmount != ".00"
        ) {
          let _item = { term: "Торгууль", closingAmount: 0 };
          this.$data.payment.undueLoss = this.$data.items[0].feeAmount;
          _item.closingAmount = this.$data.payment.undueLoss;
          this.$data.items.push(_item);
        }

        if (
          this.$data.items[0].overPaymentAmount != "0" &&
          this.$data.items[0].overPaymentAmount != ".00"
        ) {
          let _item = { term: "Илүү төлөлт", closingAmount: 0 };
          this.$data.payment.over = this.$data.items[0].overPaymentAmount;
          _item.closingAmount = this.$data.payment.over;
          this.$data.items.push(_item);
        }

        if (
          this.$data.items[0].underPaymentAmount != "0" &&
          this.$data.items[0].underPaymentAmount != ".00"
        ) {
          let _item = { term: "Дутуу төлөлт", closingAmount: 0 };
          this.$data.payment.under = this.$data.items[0].underPaymentAmount;
          _item.closingAmount = this.$data.payment.under;
          this.$data.items.push(_item);
        }
      }
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    submitSearch: function() {
      this.$data.query.search = this.$data.search;
    },
    async fetchData() {
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(
          this.$config.API_URL + "CustomerWebService/get_loan_account_history",
          {
            params: {
              page: 1,
              per_page: 0,
              orderDirection: this.$data.query.orderDirection,
              orderBy: this.$data.query.orderBy,
              search: this.$data.query.search,
              columns: this.$data.query.columns,
              id: this.$data.query.id,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        );
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }

        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          _item.closingAmount = "";
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },

    overdueLoanRepayment(_isOverdue) {
      this.isLoading = true;

      this.$http
        .post(
          this.$config.API_URL +
            "CustomerWebService/overpayment_to_overdue_payment",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              customerId: this.$route.params.id,
            }),
            isOverdue: _isOverdue,
          },
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            // (console.logresponse);
            this.isLoading = false;
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast("Амжилттай", "Амжилттай бүртгэлээ", "success");
              this.loadData();
            } else {
              this.showToast(
                "Алдаа",
                "Алдаа гарсан байна. Дахин оролдоно уу",
                "danger"
              );
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast(
              "Алдаа",
              "Алдаа гарсан байна. Дахин оролдоно уу",
              "danger"
            );
          }
        );
    },
  },
};
</script>
