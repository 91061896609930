<template>
  <b-card>
    <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
    </loading>
    <b-form-group label="Утас:" label-for="input-change-phone">
      <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone" v-model="message.phone" required
        placeholder="Утас" @keypress="isNumber($event)"></b-form-input>
    </b-form-group>

    <b-form-group label="Мессеж илгээх төрөл">
      <treeselect v-model="selected_type" :disable-branch-nodes="true" :show-count="true" :options="note_types" />
    </b-form-group>
    <b-form-textarea v-model="message.text" :state="message.text.length < 160"
      placeholder="Илгээх мессежийг бичнэ үү"></b-form-textarea>
    <b-button variant="info" style="margin-top:16px" class="mr-3" @click="sendMessageToUser(customer)">Илгээх</b-button>
    <pre class="mt-4 pull-right">160/{{ message.text.length }}</pre>
  </b-card>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axios from "axios";

export default {
  name: "Customer.Message",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function () {
    return {
      isLoading: false,
      message: {
        userID: this.customerId,
        text: "",
        type: null,
        phone: "",
      },
      selected_type: null,
      note_types: [],
      sms_result: null,
    };
  },
  watch: {
    selected_type: {
      handler(selected_type) {
        this.$data.message.type = selected_type;
        if (selected_type == 365) {
          this.getSmmText(true);
        } else if (selected_type == 364) {
          this.getSmmText(false);
        }
      },
      deep: true,
    },
    "customer.phoneNumber": {
      handler(phoneNumber) {
        this.message.phone = phoneNumber;
      },
      deep: true,
    },
  },
  created() {
    this.getLoadTypes();
  },
  methods: {
    getLoadTypes: function () {
      // this.$http
      //   .get(
      //     this.$config.API_URL +
      //     "CustomerWebService/get_note_type_list_with_id",
      //     {
      //       params: { parentID: 251 },
      //       headers: this.$store.getters.httpHeader,
      //       emulateJSON: true,
      //     }
      //   )
      axios
      .post(
            this.$config.NUMUR_MS_ADMIN_URL + "customer/get_note_type_list_with_id",
            {
              parentID: 251,
            },
            {
                headers: {
                    ...this.$store.getters.httpHeader, // Include your custom headers
                    "Content-Type": "application/json", // Ensure JSON content type
                },
            }
          )
        .then(
          (response) => {
            this.$data.note_types = response.data.data;
          },
          (response) => {
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        );
    },
    getSmmText: function (_is_closed) {
      this.isLoading = true;
      this.$data.resultType = "text-primary";
      this.$http
        .post(
          this.$config.API_URL +
          "CustomerWebService/create_pay_request_with_sms",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              is_close: _is_closed,
              customer_id: this.customerId,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.message.text = response.body.successData;
            } else {
              let _ms = response.body.failureMessages.message;
              for (var i in _ms) {
                this.showToast("Анхаар", _ms[i].failureMessage, "warning");
              }
            }
            this.isLoading = false;
          },
          (response) => {
            this.showToast(
              "Анхаар",
              "Мессеж текст гаргахад алдаа гарлаа",
              "warning"
            );
            this.isLoading = false;
          }
        );
    },
    sendMessageToUser: function (item) {
      //   //console.log(this.$data.message.type);
      this.isLoading = true;
      this.$data.message.email = this.$store.getters.email;
      axios
        .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/send_sms",
          JSON.stringify(this.$data.message),
          {
            headers: {
              ...this.$store.getters.httpHeader, // Include your custom headers
              "Content-Type": "application/json", // Ensure JSON content type
            },
          })
        // this.$http
        //   .post(
        //     this.$config.API_URL + "CustomerWebService/send_sms",
        //     this.$data.message,
        //     { headers: this.$store.getters.httpHeader, emulateJSON: true }
        //   )
        .then((response) => {
          this.isLoading = false;
          console.log("message log: ", response)
          if (response.data.success === true) {
            this.showToast("Амжилттай", "Амжилттай илгээлээ", "success");
            this.callback();
          }
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>