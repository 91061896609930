var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false,"color":this.$config.LOADER.color,"opacity":this.$config.LOADER.opacity,"background-color":this.$config.LOADER.backgroundColor,"z-index":10000},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-alert',{attrs:{"show":_vm.internationalData.is_confirm}},[(_vm.customer.isContract)?_c('span',[_vm._v("Хэрэглэгчийн зээлийн эрх мэдээлэл "),_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{
          name: 'admin.customer.income_info',
          params: { id: _vm.customer.id },
        }}},[_vm._v("Харах")])],1):_c('span',[_vm._v("Хэрэглэгчийн зээлийн эрх үүсгэх "),_c('b-button',{attrs:{"variant":"primary","size":"sm","to":{
          name: 'admin.customer.income_info',
          params: { id: _vm.customer.id },
        }}},[_vm._v("үүсгэх")])],1)]),_c('b-alert',{attrs:{"show":!_vm.internationalData.is_confirm,"variant":"danger"}},[_vm._v("Хэрэглэгч бүртгэлээ баталгаажуулаагүй байна "),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.sendEmail}},[_vm._v("имэйл явуулах")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }