<template>
  <b-card>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <div>
      <b-select v-model="selected_type" class="col-md-3">
        <option :value="false">Эргэн төлөлт</option>
        <option :value="true">Зээл хаалт</option>
      </b-select>
      <b-button variant="success" class="ml-3" @click="createPayRequest()"
        >Гүйлгээний утга үүсгэх</b-button
      >
    </div>
    <div v-if="result">
      <h4 class="mt-3 mb-0 ">
        <span :class="resultType">{{ result }}</span>
      </h4>
    </div>
  </b-card>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axios from "axios";

export default {
  name: "Customer.PayRequest",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function() {
    return {
      isLoading: false,
      selected_type: false,
      result: "",
      resultType: "text-primary",
    };
  },
  methods: {
    createPayRequest: function() {
      this.isLoading = true;
      this.$data.resultType = "text-primary";
      axios
      .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/create_pay_request",
            {
              "email": this.$store.getters.email,
              "is_close": this.$data.selected_type,
              "customer_id": this.customerId
            },
            {
              headers: {
                ...this.$store.getters.httpHeader, // Include your custom headers
                "Content-Type": "application/json", // Ensure JSON content type
              },
        })
        .then(
          (response) => {
            console.log("pay_request: ", response)
            if (response.data.message == "success") {
              this.$data.resultType = "text-primary";
              this.$data.result = response.data.data;
            } else {
              this.$data.resultType = "text-danger";
              this.$data.result = "Алдаа гарсан байна";
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
            this.$data.resultType = "text-danger";
            this.$data.result = "Алдаа гарсан байна";
          }
        );
    },
  },
};
</script>
