<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <b-alert :show="internationalData.is_confirm">
      <span v-if="customer.isContract"
        >Хэрэглэгчийн зээлийн эрх мэдээлэл
        <b-button
          variant="primary"
          size="sm"
          :to="{
            name: 'admin.customer.income_info',
            params: { id: customer.id },
          }"
          >Харах</b-button
        ></span
      >
      <span v-else
        >Хэрэглэгчийн зээлийн эрх үүсгэх
        <b-button
          variant="primary"
          size="sm"
          :to="{
            name: 'admin.customer.income_info',
            params: { id: customer.id },
          }"
          >үүсгэх</b-button
        ></span
      >
    </b-alert>
    <b-alert :show="!internationalData.is_confirm" variant="danger"
      >Хэрэглэгч бүртгэлээ баталгаажуулаагүй байна
      <b-button
          variant="danger"
          size="sm"
          @click=" sendEmail"
          >имэйл явуулах</b-button
        >
      </b-alert
    >
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import axios from "axios";

export default {
  name: "Customer.PayRequest",
  props: ["customerId", "customer", "callback"],
  components: {
    Treeselect,
  },
  data: function() {
    return {
      isLoading: false,
      internationalData: null,
    };
  },
  created() {
    this.getInternationCode();
  },
  methods: {
    getInternationCode: function() {
      this.isLoading = true;
      this.$data.internationalData = null;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_international_user",
          {
            params: {
              id: this.customerId,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
      // axios
      //   .post(this.$config.NUMUR_MS_ADMIN_URL + "customer/get_international_user",
      //         {
      //           id: this.customerId
      //         },
      //         {
      //         headers: {
      //           ...this.$store.getters.httpHeader, // Include your custom headers
      //           "Content-Type": "application/json",
      //         },
      //   })
        .then(
          (response) => {

            // if (response.data.message == "success") {
            //   this.$data.internationalData = {
            //     is_confirm: response.data.data.isConfirm,
            //     confirm_date: response.data.data.confirmDate,
            //     id: response.data.data.id,
            //   };
            //   console.log("internationalData: ", this.$data.internationalData)
            // }
            // if (response.data.message == "success") {
            //   this.$data.internationalData = {
            //     is_confirm: response.data.data.isConfirm,
            //     confirm_date: response.data.data.confirmDate,
            //     id: response.data.data.id,
            //   };
            //   console.log("internationalData: ", this.$data.internationalData)
            // }
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.internationalData = {
                is_confirm: response.body.isConfirm,
                confirm_date: response.body.confirmDate,
                id: response.body.id,
              };
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    sendEmail: function() {
      this.isLoading = true;
      this.$data.internationalData = null;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/confirm_email_send",
          {
            params: {
              id: this.customerId,
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
      // axios
      //     .post(this.$config.TEST_FLASK_ADMIN_URL + "customer/confirm_email_send",
      //         {
      //           "id": this.customerId
      //         },
      //         {
      //         headers: {
      //           ...this.$store.getters.httpHeader, // Include your custom headers
      //           "Content-Type": "application/json",
      //         },
      //     })
      //     .then(
      //       (response) => {
      //         console.log(response);
      //         if (response.data.message === "success") {
      //           this.$data.internationalData = {
      //             is_confirm: response.body.isConfirm,
      //             confirm_date: response.body.confirmDate,
      //             id: response.body.id,
      //           };
      //         }
      //         this.isLoading = false;
      //       },
      //       (response) => {
      //         this.isLoading = false;
      //       }
      //     );
        .then(
          (response) => {
            console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.internationalData = {
                is_confirm: response.body.isConfirm,
                confirm_date: response.body.confirmDate,
                id: response.body.id,
              };
            }
            this.isLoading = false;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    }
  },
};
</script>
